<template >
    <div>
        <div class="app-modal__box">
            <div class="app-modal__in">
                <div class="app-modal__header d-flex f-between p-5 m-title-modal" >
                    <p class="large--title m-0">
                        {{
                            $t("message.new_m", {
                                m: $t("message.business_trip"),
                            })
                        }}
                    </p>
                    <div>
                        <crm-store-update-close
                            :permission="$options.name"
                            :button_type="'store'"
                            :loading="loadingButton"
                            @c-submit="submit(true)"
                            @c-close="close()"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <div class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right rounded-sm w-100 p-4">
                  <el-form
                      ref="form"
                      status-icon
                      :model="form"
                      :rules="rules"
                      >
                    <el-row :gutter="20">
                      <el-col :span="24">
                        <el-row :gutter="20">
                          <el-col :span="22">
                            <div class="app-form__group mb-4">
                              <span class="input--label d-block mb-2">
                                {{ $t("message.organizations") }}
                              </span>
                              <el-form-item>
                                <select-organization
                                  :id="form.organization_id"
                                  :size="'medium'"
                                  v-model="form.organization_id"
                                  :placeholder="$t('message.organizations')"
                                  className="w-100"
                                >
                                </select-organization>
                              </el-form-item>
                            </div>
                          </el-col>
                          <el-col :span="2">
                              <div class="pulus " style="height: 36px;  margin-top: 27px;" @click="organizationCreateModel = true">
                                <i class="el-icon-plus"></i>
                              </div>
                            </el-col>
                          <el-col :span="24">
                            <el-form-item prop="manager_id">
                              <span class="input--label d-block mb-0 line-h-24"
                                >{{$t('message.responsible')}}</span
                              >
                              <select-staff
                                :id="form.manager_id"
                                :size="'medium'"
                                v-model="form.manager_id"
                              ></select-staff>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item prop="staff_ids">
                              <span class="input--label d-block mb-0 line-h-24"
                                >{{$t('message.business_travelers')}}</span
                              >
                              <select-staff
                                :id="form.staff_ids"
                                :size="'medium'"
                                :multiple="true"
                                v-model="form.staff_ids"
                              ></select-staff>
                            </el-form-item>
                          </el-col> 
                          <el-col :span="12">
                            <span class="input--label d-block">
                              {{ $t("message.start_date") }}
                            </span>
                            <el-form-item prop="start_date" class="label_mini">
                              <crm-date-picker
                                :date="form.start_date"
                                value-format="yyyy-MM-dd"
                                :size="'medium'"
                                :placeholder="$t('message.start_date')"
                                v-model="form.start_date"
                              >
                              </crm-date-picker>
                            </el-form-item>
                          </el-col>
                          <el-col :span="12">
                            <span class="input--label d-block">
                              {{ $t("message.start_time") }}
                            </span>
                            <el-form-item prop="start_time" class="label_mini">
                              <el-time-picker
                                value-format="HH:mm:ss"
                                format="HH:mm:ss"
                               :size="'medium'"
                                :placeholder="$t('message.start_time')"
                                v-model="form.start_time"
                                style="width: 100%"
                              ></el-time-picker>
                            </el-form-item>
                          </el-col>
                          <el-col :span="12">
                            <span class="input--label d-block">
                              {{ $t("message.end_date") }}
                            </span>
                            <el-form-item prop="end_date" class="label_mini">
                              <crm-date-picker
                                :date="form.end_date"
                                value-format="yyyy-MM-dd"
                                :size="'medium'"
                                :placeholder="$t('message.end_date')"
                                v-model="form.end_date"
                              >
                              </crm-date-picker>
                            </el-form-item>
                          </el-col>
                          <el-col :span="12">
                            <span class="input--label d-block">
                              {{ $t("message.end_time") }}
                            </span>
                            <el-form-item prop="end_time" class="label_mini">
                              <el-time-picker
                                value-format="HH:mm:ss"
                                format="HH:mm:ss"
                               :size="'medium'"
                                :placeholder="$t('message.end_time')"
                                v-model="form.end_time"
                                style="width: 100%"
                              ></el-time-picker>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item
                                  :label="$t('message.business_trip_purpose')"
                                  prop="business_trip_purpose"
                                  class="label_mini"
                              >
                                  <crm-input
                                      :size="'medium'"
                                      :inputValue="form.purpose"
                                      v-model="form.purpose"
                                      :placeholder="$t('message.business_trip_purpose')"
                                  ></crm-input>
                              </el-form-item>
                          </el-col> 
                          <el-col :span="24" class="company_info" >
                            <el-form-item
                                :label="$t('message.business_trip_tasks')"
                                prop="business_trip_tasks"
                                class="label_mini">
                                <el-input
                                    type="textarea"
                                    :rows="1"
                                    :placeholder="$t('message.business_trip_tasks')"
                                    :inputValue="form.tasks"
                                    v-model="form.tasks"
                                    class="text-area-el"
                                ></el-input>
                            </el-form-item>
                          </el-col> 
                        </el-row>
                      </el-col>
                      <!-- <el-col :span="12">
                        <div class="app-form__group mb-4">
                            <span class="input--label d-block mb-2">
                              {{ $t("message.phone_number") }}
                            </span>
                            <el-form-item prop="phone_number">
                              <crm-input
                                :type="'tel'"
                                :maskFormat="'tel'"
                                :placeholder="$t('message.phone_number')"
                                :inputValue="form.phone_number"
                                v-model="form.phone_number"
                                :size="'medium'"
                              ></crm-input>
                            </el-form-item>
                          </div>
                      </el-col> -->
                    
                    </el-row>
                  </el-form>
                </div>
            </div>
            <!-- end app-modal__body -->
        </div>
        <el-dialog
          :visible.sync="organizationCreateModel"
          width="60%"
          :append-to-body="true"
          center
          ref="organizationCreateModel"
        >
          <create-organization
            @closeRegionModal="closeRegionModal"
            ref="organizationCreateModelChild"
            dialog="organizationCreateModel"
          ></create-organization>
      </el-dialog>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import selectOrganization from "@/components/filters/inventory/select-organization";
import createOrganization from "./create-organization.vue";
/**
 * Drawer chaqirish kerak drawerni yopish uchun
 */
import form from "@/utils/mixins/form";
export default {
    mixins: [form, drawer],
    name: "CompanyController",
    components:{
      selectOrganization,
      createOrganization
    },
    data() {
        return {
           organizationCreateModel:false,
        };
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "businessTrip/rules",
            model: "businessTrip/model",
            columns: "businessTrip/columns",
        }),
    },
    methods: {
        ...mapActions({
            save: "businessTrip/store",
            empty: "businessTrip/empty",
        }),
        
        //  createImageList(file) {
        //   this.form.image = file.raw;
        // },
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    // let formData = new FormData();
                    // for (const key in this.form) {
                    //   formData.append(key, this.form[key]);
                    // }
                    this.save(this.form)
                        .then((res) => {
                            this.empty();
                            // this.fileList = [];
                            // this.imageList = [];
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
        closeRegionModal(val) {
          this.organizationCreateModel = val;
        },
    },
};
</script>
<style  lang="scss">

.my-upload .el-upload-list {
    position: relative;
    z-index: 2;
}
.my-upload .el-upload.el-upload--picture-card {
    position: absolute;
    left: 0px !important;
    z-index: 1;
    width: 91%;
    line-height: 150px;
    vertical-align: top;
    
}
.my-upload .upload-demo {
    position: relative;
}



</style>
